.elink-container {
    max-width: 1170px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

.d-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.j-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.j-even {
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
}

.j-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.j-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.al-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

p {
    font-size: 14px;
    margin-bottom: 0px;
    line-height: 24px;
}

.m-top-3 {
    margin-top: 3rem;
}

.m-top-2 {
    margin-top: 2rem;
}

.m-top-0-5 {
    margin-top: 0.5rem;
}

.m-bottom-0-5 {
    margin-bottom: 0.5rem;
}
.m-bottom-1-5 {
    margin-bottom: 1.5rem;
}
.m-bottom-2 {
    margin-bottom: 2rem;
}

.m-top-1 {
    margin-top: 1rem;
}

.m-top-5 {
    margin-top: 5rem;
}

.m-top-auto-1 {
    margin: 1rem auto 0px;
}

.p-left-1 {
    padding-left: 1%;
}

.p-right-1 {
    padding-right: 1%;
}

.p-left-15-p {
    padding-left: 15px;
}

.p-left-right-25 {
    padding: 0px 25px;
}

.p-top-5 {
    padding-top: 5rem;
}

.h-100 {
    height: 100%;
}

#root .p-b-0 {
    padding-bottom: 0px;
}

.blue-button {
    background-color: #007cba;
    border: 0px;
    color: #fff;
    padding: 7px 18px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 4px solid #00a3e0;
}
.blue-button:hover, .blue-button:active, .blue-button:focus{
    transition: all .25s ease-in-out;
}

.shipping_icon i {
    font-size: 22px;
    color: #007dba !important;
    cursor: pointer;
    vertical-align: bottom;
    margin-top: 5px;
}

.ship-addr-col{
    margin-bottom: 1rem;
}

.marin-top-8{
    margin-top: 8px
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 42px;
    border-radius: 5px;
    border: 1px solid #c8c9c7;
    padding-top: 4px;
}

#root .blue-button-phone {
    padding: 10px;

    a {
        color: #fff;
        font-size: 13px;

        i {
            margin-right: 6px;
            font-size: 14px;
            margin-left: 0px;
        }
    }
}

.grey-button {
    background-color: #d9d9d6;
    color: #000;
    border-color: #b1b3b3;
}

.light-blue {
    background-color: #7fbddc;
    border-color: #51aedd;
}

.same-common input,
#root .address-on-card .ant-select-selector {
    height: 42px;
    padding: 6px 11px;
    border: 1px solid #c8c9c7;
    display: inline-block;
    width: 100%;
    border-radius: 5px;
    border-bottom-width: 4px;
}

.same-common .ant-checkbox-wrapper input {
    height: auto;
}


.d-none,
.hide-items {
    display: none;
}

.review-button button {
    margin: 0.5rem auto 1rem;
}

.fixed-on-scroll {
    overflow: auto;
    position: sticky;
    top: 100px;
}

.fixed-on-scroll-with-tab {
    top: 180px;
}

#root .mobile-info-last {
    padding: 3px 15px 0px;
    font-size: 13px;
    margin: 0px;
}

.main-outer {
    height: calc(100vh - 117px);
    position: relative;

    .outer-loader {
        text-align: center;
        background-color: #f7f7f7;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);

        img {
            width: 150px;
            margin-bottom: 15px;
        }
    }
}

.loader-text {
    font-weight: bold;
    color: #000;
    text-align: center;
    font-size: 16px;
    background-color: #f7f7f7;


    .slick-dots {
        display: none !important;
    }

    .carousel-outer {
        i {
            color: #007cba;
            font-size: 20px;
            margin-right: 4px;

        }
    }

    .no-throttling p {
        font-size: 18px;
        font-weight: 600;
        margin-top: 1rem;

        i {
            color: #117cba;
            font-size: 24px;
            vertical-align: text-bottom;
            margin-right: 5px;
        }
    }
}

.blue-button:disabled {
    cursor: not-allowed;
    background-color: #7fbddc;
    border-color: #51aedd;
    color: #fff;
}

.blue-border {
    border-color: #007dba !important;
}

.ant-picker-cell-disabled {
    cursor: not-allowed !important;
    pointer-events: auto !important;
}

#root .ant-form-item-has-error input,
#root .ant-form-item-has-error input:hover,
#root .ant-form-item-has-error input:focus,
#root .main-checkout .ant-form-item-has-error .ant-checkbox-input + .ant-checkbox-inner {
    border-color: #ff4d4f !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.border-red { 
    border: 1px solid #ff4043 !important;
    border-radius: 6px;
}

.red-color {
    color: #ff4d4f;
    font-weight: 500;
}

.text-green {
    color: green;
    font-weight: 500;
}

#root #basic_serviceAddress.ant-input-disabled {
    color: rgba(0, 0, 0, 0.6);
}

.mobileTop {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
}

.ant-modal-title {
    color: #007dba;
    font-weight: 600;
    font-size: 20px;
}

.ant-modal-close-x {
    width: auto;

    span {
        height: 25px;
        width: 25px;
        background-color: #007dba;
        border-radius: 50%;
        margin-top: 14px;
        margin-right: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        svg {
            width: 13px;
            fill: #fff;
        }
    }
}

.dotted {
    border-bottom: 1px dotted grey;
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    top: 9px;
    z-index: 0 !important;
}

.p-relative {
    position: relative;
}

.hide-lable label {
    display: none;
}

#root .same-select-design {

    .ant-select-selector {
        height: 42px;
        border: 1px solid #c8c9c7;
        border-radius: 5px;
        border-bottom-width: 4px;

        .ant-select-selection-item,
        .ant-select-selection-placeholder {
            padding-top: 3px;
        }
    }
}

.b-bg-color {
    background-color: #f7f7f7;
    padding: 10px;
}

.mobile-info {
    .b-bg-color {
        padding: 5px 0px;
        border-radius: 4px 4px 0px 0px;
    }
}

span.seemore {
    width: 100%;
    text-align: left;
    padding-left: 20px;
    font-size: 16px;
    color: #007cb0;
    font-weight: 500;
    margin: 8px 0px 15px;
    cursor: pointer;
    display: inline-block;
    text-decoration: underline;
}

.vas-single-prod {
    .seemore {
        margin-bottom: 8px;
        margin-top: 0px;
        padding: 0px;
        text-decoration: underline;
    }
}

.vas-selection {
    height: 42px;
    border: 1px solid #c8c9c7;
    display: inline-block;
    width: 100%;
    border-radius: 5px;
    border-bottom-width: 4px;
    background-color: #fff;
    padding-left: 6px;

    &:focus-visible {
        outline: none;
    }
}

.location-icon {
    .ant-select-selection-search {
        &:before {
            position: absolute;
            content: '\f3c5';
            font-family: "Font Awesome 6 Free";
            font-weight: 900;
            top: 4px;
            left: 0px;
            font-size: 18px;
        }

        input {
            padding-left: 20px !important;
        }

    }

    .ant-select-selection-item {
        padding-left: 20px !important;
    }
}

.vas-container {
    max-width: 770px;
    margin: 0 auto;
}

#root .no-tabs {
    margin-top: 0px;

    .mobilephone-outer {
        margin-top: 0px;
    }

    >.ant-row {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        margin: 0 -10px;
        > .ant-col{
            padding: 0 10px;
        }
    }
}

#root .serviceabilityCheck .ant-select-selection-placeholder {
    padding-left: 24px;
    padding-top: 4px;
    display: inline-block;
}

.info-tag {
    font-size: 18px;
    color: red;
    font-weight: 600;
    margin-bottom: 25px;
}

.border-same {
    border: 2px solid #c8c9c7;
    border-radius: 5px;
    border-bottom-width: 5px;
}

.width-full-img {
    width: 100%;
}

.terms-info {
    margin-bottom: 2rem;

    .card-body {
        max-height: 250px;
        overflow-y: scroll;
        border-radius: 0px 0px 10px 10px;

        h4 {
            padding-left: 13px;
            color: #007dba;
            font-weight: 600;
        }

        &::-webkit-scrollbar-track {
            background-color: #F0F0F0;
            border-radius: 0px 0px 10px 0px;
        }

        &::-webkit-scrollbar {
            width: 8px;
            border-radius: 0px 0px 10px 0px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: #C1C1C1;
        }
    }
}

.ant-scrolling-effect {
    width: 100% !important;
    overflow: inherit !important;
}

#root .first-top {
    padding-top: 10px;
    margin-top: -5px;
}

#root .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.disable-pointer {
    pointer-events: none;
}

#root .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #007cba;
    border-color: #007cba;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after{
    border-color: #fff;
}

#root .ant-checkbox-checked::after {
    border-color: #007cba;
}
.text-captilize{
    text-transform: capitalize;
}
#root .loader-height-auto .loader-out{
    height: auto;
}
.bundle-discount{
    color: #156e00;
}

.whi-modal {

    .heading {
        font-weight: 600;
    font-size: 21px;
    margin-bottom: 20px;
    line-height: 29px;
    }
    ul {
        list-style-type: none;
        padding-left: 25px;
        font-size: 18px;
        li {
            margin-bottom: 10px;
            position: relative;

            &::before {
                position: absolute;
                content: "";
                height: 8px;
                width: 8px;
                background-color: #007cb0;
                border-radius: 50%;
                left: -17px;
                top: 8.7px;
            }
        }
    }
    .ant-btn-primary {
        background-color: #007cb0;
    }
}

.closeable-btn{
    color: #000;
    background-color: transparent !important;
    font-size: 2rem;
    font-weight: 400;
}

.bolder-text {
    font-weight: 800;
}

 .address-modal .ant-modal-close{
    top: 0;
}

.ant-btn[disabled], .ant-btn[disabled]:active, .ant-btn[disabled]:focus, .ant-btn[disabled]:hover {
    background: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    color: #00000040 !important;
    text-shadow: none !important;
    cursor: not-allowed !important;
}

.address-modal .ant-modal-close:hover {
    color: transparent !important;
    background-color: transparent !important; 
}

.address-modal .ant-input {
    border-radius: 0;
}

.model-title .ant-modal-close{
top: 0;
}

.model-title .ant-modal-close-x{
 display: block;
}