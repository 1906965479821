#root .main-checkout {
  .checkout-accordion {
    border: none;
    border-radius: 5px;
    counter-reset: my-awesome-counter;
    list-style: none;
  }

  .ant-collapse > .ant-collapse-item {
    border: none;
    position: relative;
    padding: 30px 25px;
    margin-bottom: 15px;
    background-color: #fff;
    border-radius: 8px;

    .edit-tab-icon {
      position: absolute;
      top: 21px;
      right: 35px;

      i {
        color: #007dba;
        font-size: 22px;
        cursor: pointer;
      }
    }
    counter-increment: my-awesome-counter;
  }

  .activation_number_collapse {
    .ant-collapse-item {
      padding: 0;
      margin-bottom: 20px;
    }
  }

  .ant-collapse-header > div > span {
    display: none;
  }

  .ant-collapse-item-active .ant-collapse-header {
    padding-bottom: 0px;
  }

  .pnl {
    position: relative;
    &:before {
      content: counter(my-awesome-counter);
      height: 28px;
      width: 28px;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      color: #fff;
      background-color: #f68d1f;
      text-align: center;
      border-radius: 100%;
      position: absolute;
      top: 27px;
      left: 24px;
      z-index: 9;
    }
    &.checkout_span_ok {
      &:before {
        content: "\f058";
        font-family: "Font Awesome 6 Free";
        font-size: 26px;
        font-weight: 900;
        color: #3dae2b;
        background: none;
      }
    }

    .checkout_edit {
      font-size: 13px;
      line-height: 15.85px;
      color: #007dba;
      align-self: center;
      background: transparent;
      border: none;
      font-weight: 800;
      cursor: pointer;
    }
  }

  .ant-collapse-header {
    color: #444444;
    font-size: 20px;
    line-height: 24.38px;
    font-weight: 800;
    background-color: #fff;
    padding: 0;
    padding-left: 40px;

    .ant-collapse-header-text {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .ant-collapse-content-active {
    border-top: unset;
  }

  .ant-collapse-content-box {
    padding: 20px 0;
  }

  .calender-row {
    .main-calender {
      padding: 0px 20px;

      .schedule-header i {
        color: #007dba;
        cursor: pointer;
      }
    }

    h3.under-tab-heading {
      font-size: 16px;
      font-weight: 600;
      margin: 15px 0px;
      color: #333333;
    }

    .arrival-window-button {
      button {
        color: #444444;
        border: 1px solid #b9b9b9;
        background-color: #fff;
        padding: 12px 20px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        border-radius: 4px;
        width: 88%;
        margin-bottom: 20px;
        cursor: pointer;
        i {
          margin-right: 30px;
          font-size: 18px;
        }
        &.blue-border {
          border-color: #f68d1f !important;
          color: #f68d1f;
          position: relative;
          .fa-check-circle {
            margin-right: 0;
            color: #3dae2b;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    .calender-below-buttons {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
      -ms-flex-pack: distribute;
      justify-content: center;
      margin: 1rem auto;
      column-gap: 10px;
    }

    .calender-below-buttons button {
      min-width: 120px;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }

  .main-arrival-wnidow button.under-tab-heading {
    margin-bottom: 2.1rem;
  }

  /* compl check */
  .compatible-tab-checkout {
    margin-bottom: 10px;
    button {
      padding: 7px 27px;
    }
    button:first-child {
      margin-right: 15px;
    }
    button:last-child {
      margin-left: 15px;
    }

    p {
      font-size: 18px;
      padding: 5px 0px 10px;
      color: #444444;
    }

    span.title-imei {
      color: #777777;
      font-weight: 600;
      font-size: 18px;
      margin: 1rem 0px 4px;
      display: inline-block;
    }

    .compatible-input {
      margin-right: 15px;

      > .ant-form-item {
        display: block;
        margin-bottom: 10px;
      }
      .ant-form-item-explain.ant-form-item-explain-connected {
        position: absolute;
        bottom: -24px;
      }
    }
    .only-check {
      margin-top: 2.5rem !important;
    }
    .check-another {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
    }

    .compatible-enter-info button {
      margin: 25px 15px 0;
    }
  }

  .installNum {
    border: none;
    .ant-collapse-content-box {
      background-color: #f1f1f1;
      border-radius: 6px;
      padding: 10px;
      margin-bottom: 10px;

      .ant-form-item {
        margin: 0px;
      }

      .ant-col-8 {
        .ant-row {
          justify-content: end;
          align-items: center;
        }

        label {
          margin-right: 10px;
        }
      }
    }

    .ant-collapse-header {
      padding: 0px;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;

      .ant-collapse-header-text {
        color: #333333;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 2px;
        margin-left: 1px;

        span {
          color: red;
        }
      }
    }

    .ant-collapse-expand-icon {
      i {
        font-size: 22px;
        margin: 0px;
      }
    }

    .ant-collapse-item {
      border: none;
    }

    .ant-select {
      height: 42px;
      border: 1px solid #c8c9c7;
      width: 100%;
      border-radius: 5px;
      border-bottom-width: 4px;

      .ant-select-selection-placeholder {
        color: #444444 !important;
      }

      .ant-select-selector {
        border: none;
        padding-top: 4px;
        height: 37px;
        border-radius: 5px;
        width: 160px;

        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}

/* Enter your info css */

.your-info-tab {
  .your-info-input > .ant-row {
    display: block;
  }

  .yor-info-row {
    padding-top: 10px;
    margin: 0px -15px;

    .your-info-input {
      padding: 0px 15px;
    }
  }

  .your-info-beolow-des {
    margin: 0 auto;
    label {
      margin-left: 0px;
      margin-bottom: 5px;
      color: #444444 !important;
      font-size: 13px;
      line-height: 18px;
      display: flex !important ;

      span {
        margin-top: 2px;
        font-weight: normal;
        align-self: start !important;
      }
    }
  }

  label {
    color: #7e7e81 !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.7px;
    height: auto;
    margin-bottom: 5px;

    &:after {
      display: none;
    }
  }
   
  input[type="tel"]::placeholder {
    opacity: 0.6 !important;
    color: #aaa;
  }

  input[type="tel"]::placeholder {
    opacity: 0.6 !important;
    color: #aaa;
  }

  input[type="tel"]:hover,
  input[type="tel"]:focus {
    border-color: #1677ff;
    outline: 0;
    background-color: #fff;
  }

 
  input[type="text"] {
    border-bottom-width: 1px;
  }

  .enter-your-info .blue-button {
    display: inline-block;
    padding: 8px 35px;
    font-size: 16px;
  }

  .primary-info {
    margin-bottom: 10px;
    font-size: 16px;
    color: #444444;
    a {
      font-weight: 600;
    }
  }
}

.payment-info {
  .address-on-card {
    margin-bottom: 10px;
  }

  .agree_checkbox {
    margin-bottom: 15px;
  }

  .ant-checkbox-wrapper span {
    font-weight: normal;
    font-size: 14px;
    margin-bottom: -0.5px;
    color: #333333;
  }

  .agree-payment {
    color: #444444;
    margin: 1rem 0px 4px;

    a {
      text-decoration: underline;
    }
  }

  .zip-code-row {
    background-color: #f7f7f7;
    padding: 15px;
    border: 2px solid #c8102e8a;
    border-radius: 3px;
    margin: 10px 0px;

    .fourg-image {
      img {
        width: 75%;
      }
    }

    .zip-cdoe-col {
      .ant-form-item {
        display: block;
        margin: 0px;
        padding-right: 10px;

        label {
          color: #777777;
        }
      }
    }

    .zip-code-check-btn {
      padding-left: 10px;

      button {
        width: 100%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-top: 2rem;
      }
    }
  }
}

.compatible-tab-checkout .ant-form-item-label label {
  color: #777777;
  font-weight: 600;
  font-size: 18px;
  margin: 1rem 0px 4px;
  display: inline-block;
}

.same-common .ant-form-item-label :after {
  display: none;
}

.label {
  label {
    color: #333333;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 2px;
    margin-left: 1px;

    &:after {
      display: none;
    }
  }

  .ant-col .ant-row.ant-form-item {
    display: block;
  }
}

.create-account {
  .input-type-span {
    position: relative;
  }

  .input-span {
    position: absolute;
    right: 20px;
    bottom: 34px;
    font-size: 16px;
    font-weight: 500;
    background-color: #fff;
    padding-left: 10px;
  }

  .Check-account {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 2rem;
  }
}

.suggestedIds {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.suggestionsContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style-type: none;
  margin-bottom: 10px;
  margin-left: 10px;

  li.suggestions {
    padding-right: 14px;
    color: #007dba;
    font-weight: 700;
    cursor: pointer;
  }
}

.email-suggest-msg {
  margin-top: -20px;
  margin-bottom: 10px;
}

.id-suggest-msg {
  margin: 0px 0px 10px;
}

.ant-select-status-error {
  border-color: #ff4d4f !important;
}

#root .ant-form-item-has-error {
  span.ant-input-password {
    border-color: #ff4d4f !important;
  }
}

.mobile-type-input {
    color: #000 !important;
  width: 11rem !important;
}

.qauestion-answer {
  margin: 0px -15px;

  .your-info-input {
    padding: 0px 15px;
  }
}

#root .same-common.create-account {
  input {
    height: 42px;
    border: 1px solid #b9b9b9;
    display: inline-block;
    width: 100%;
    border-radius: 5px;
  }

  span.ant-input-password {
    height: 42px;
    border: 1px solid #b9b9b9;
    width: 100%;
    border-radius: 5px;

    input {
      height: auto;
      border: none;
    }
  }

  .ant-select {
    height: 42px;
    border: 1px solid #b9b9b9;
    width: 100%;
    border-radius: 5px;

    .ant-select-selection-placeholder {
      color: #444444 !important;
    }

    .ant-select-selector {
      border: none;
      padding-top: 4px;
      height: 37px;
      border-radius: 5px;

      &:focus-visible {
        outline: none;
      }
    }
  }
}

.same-common input,
#root .address-on-card .ant-select-selector {
  border-bottom-width: 1px;
}

.result-compatible {
  margin-top: 10px;
}

.not-compatible {
  span#resultarea {
    font-size: 15px;
    padding-left: 5px;
    font-weight: 500;
    position: relative;
  }
  i {
    color: red;
  }
}

.text-compatible {
  span#resultarea {
    font-size: 15px;
    padding-left: 5px;
    font-weight: 500;
    position: relative;
  }
  i {
    color: green;
  }
}

#root .custom-label {
  margin-bottom: 4px;
  display: inline-block;
  margin-top: 5px;
  position: relative;

  .primary-num-pop {
    cursor: pointer;
    position: absolute;
    bottom: -1px;
    right: -15px;
  }
}

#InstallPhoneNum::placeholder {
  color: rgba(0, 0, 0, 0.85);
  opacity: 1;
}
#InstallPhoneNum:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.85);
}
#InstallPhoneNum::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.85);
}
.height-big-screen {
  min-height: calc(100vh - 117px);
}

// calendar style
.site-calendar-demo-card {
  .ant-picker-calendar-date {
    border-radius: 100% !important;
    height: 40px !important;
    line-height: 40px !important;
    width: 40px !important;
  }

  .schedule-header {
    margin-bottom: 10px;
    .ant-col {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: #444444;
      &.ant-col-5,
      &.ant-col-10 {
        border-radius: 8px;
      }
    }
  }

  .ant-picker-panel {
    .ant-picker-body {
      padding: 20px 0;
      .ant-picker-cell-today {
        .ant-picker-cell-inner {
          &:before {
            border-radius: 100%;
          }
        }
      }
      
      .ant-picker-cell-selected {
        .ant-picker-cell-inner {
          background-color: #f68d1f;
        }
      }
    }
  }
}

.payment-submit {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
