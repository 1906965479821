.vas-main-heading {
    .vas-heading {
        width: 100%;

        h1 {
            color: #007dba;
            font-size: 40px;
            font-weight: 800;
            width: 100%;
            text-align: center;
            margin: 10px 0px;
        }
        h2{
            color: #333333;
            font-size: 24px;
            font-weight: 600;
            margin: 0px;
        }
    }

    p {
        color: #444444;
        font-size: 18px;
        line-height: 27px;
    }
}

.vas-remove-btn {
    padding: 8px 10px;
    font-size: 13px;
}

.vas-products {

    .vas-heading {
        padding: 6px 0px 22px;
    }

    p.vas-descrption {
        font-size: 16px;
        font-weight: bold;
    }

    .marign-l {
        margin-left: 35px;
    }

    h2 {
        color: #007dba;
        font-weight: 600;
        font-size: 26px;
        margin: 0;
    }

    .vas-single-prod.vas-less {
        min-height: auto !important;

        .below-selected-part {
            position: inherit !important;
            margin-top: 10px;
        }
    }

    .vas-main-products {

        .vas-single-prod {
            border: 1px solid #D3D3D3;
            border-radius: 4px;
            min-height: auto;
            position: relative;
            background-color: #fff;

            .vas-description-col {
                padding: 25px 15px;
                min-height: 160px;
            }
        }
    }
}

.vas-products .vas-main-products .vas-single-prod .below-selected-part button i.fa-times {
    margin-left: 15px;
}

p.item-text-set {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.vas-less {
    p.item-text-set {
        display: block;
    }
}
.vas-carousel{
    .ant-carousel .slick-initialized .slick-slide{
        padding: 0 15px;
    }
    .slick-slider {
      margin: 0px -15px
    }
    .vas-selection{
        width: auto;
    }
    .item-text-set{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .vas-single-prod.vas-less{
        .item-text-set{
            display: block;
        }
    }
    .slick-arrow{
        z-index: 9 !important;
        &:before{
            content: "\f105";
            font-weight: 600;
            font-size: 22px;
            color: #007cba;
            font-family: "Font Awesome 6 Free";
            padding: 5px;

        }
    }
    .slick-prev{
        left: -9px !important;
        &:before{
            content: "\f104";
        }
    }
    .slick-next{
        right: -6px !important;
    }
    .slick-disabled {
		cursor: not-allowed !important;
        opacity: .25 !important;
	}
}

// checkout_vas_carousel_wrapp new design
.main_checkout_wrapp{
    margin-bottom: 40px;
}
.checkout_vas_carousel_wrapp{
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;

    .vas_heading{
        font-size: 20px;
        line-height: 24.38px;
        font-weight: 800;
        color: #444444;
        margin-bottom: 0;

        display: flex;
        column-gap: 15px;
        align-items: center;
    }

    .vas-products{
        margin-top: 20px;
    }

    .vas_item_heading{
        font-size: 16px;
        line-height: 19.5px;
        font-weight: 600;
        color: #444444;
    }
    .item-text-set{
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #57585C;
        margin-bottom: 10px;
    }
    span.seemore{
        font-size: 13px;
        font-weight: 600;
        color: #007DBA;
        display: inline-block;
        margin-bottom: 0;
    }
    .vas_item_list{
        margin-top: 10px;
        li {
            list-style-type: none;
            position: relative;
            color: #333333;
            padding-left: 20px;
            margin-bottom: 5px;

            &:before {
                position: absolute;
                content: "";
                height: 6px;
                width: 6px;
                background-color: #007bb0;
                left: 0;
                border-radius: 50%;
                top: 8px;
            }
        }
    }
    
    .slick-arrow{
        top: -36px !important;
        border: 1px solid #D3D3D3 !important;
        border-radius: 100%;
        height: 28px;
        width: 28px;
        opacity: 5;
        &:before{
            font-size: 16px;
            line-height: 16px;
            color: #444 !important;
            padding: 0;
           
        }
        
        &::after{
            display: none;
        }
    }
    .slick-prev{
        left: auto !important;
        right: 60px !important;
    }
    .slick-next{
        right: 20px !important;
    }

    .below-selected-part {
        padding: 15px 15px;
        border-top: 1px solid #D3D3D3;
        width: 100%;

        .vas-selection{
            font-size: 16px;
            width: 100%;
        }

        .price_wrapp{
            p{
                font-size: 20px;
                color: #444444;
                line-height: 24.38px;
                font-weight: 800;
            }
        }

        button {
            margin-left: 20px;

            i {
                margin-right: 10px;
                font-size: 16px;
            }
        }

        .ant-row.ant-form-item {
            margin: 0px;
        }

        .ant-form-item-label {
            display: none;
        }
    }
}