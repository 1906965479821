.checkout_review_heading {
  font-size: 40px;
  font-weight: 800;
  color: #444444;
}
.main-review-part {
  flex-wrap: wrap;
  column-gap: 30px;
  margin-top: 80px;

  .review-left-side {
    width: 100%;
    max-width: calc(67% - 15px);
    .rightsidebar-cart {
      border-color: #c8c9c7;
      margin-top: -70px;
      .b-top {
        border-top: 1px solid #c8c9c7;
      }
    }
    h4 {
      font-size: 40px;
      line-height: 40px;
      font-weight: 800;
      color: #444444;
      padding: 0;
      margin-bottom: 30px;
    }
    .common-cart-section {
      padding: 10px 25px;
    }
    .product-collect-heading {
      padding: 0px;

      p {
        font-size: 13px;
      }
    }
    .added-products {
      font-size: 13px;
    }

    .added-products:nth-of-type(2) {
      /* Your styles for the second div here */
      margin-bottom: 1.2rem;
    }

    .dotted {
      border-bottom: 1px dotted #333333;
      top: 14px;
    }
  }

  .review-right-side {
    width: 100%;
    max-width: calc(33% - 15px);

    .m-top-3:first-child {
      margin: 0px;
    }
  }

  .service-cart {
    ul {
      -ms-grid-columns: 2fr 2fr;
      grid-template-columns: 2fr 2fr;
      display: -ms-grid;
      display: grid;
      column-gap: 20px;
      width: 100%;
      color: #000000d9 !important;

      li {
        margin-bottom: 8px !important;
        font-size: 15px !important;
        color: #000000d9 !important;
      }

      .cart_heading {
        font-size: 15px;
        line-height: 17.25px;
        font-weight: 700;
      }
    }
  }

  .review-terms-condition {
    text-align: left;
    background-color: #fff;
    padding: 25px 20px;
    border-radius: 8px;

    .term_heading {
      font-size: 20px;
      line-height: 24.38px;
      font-weight: 800;
      color: #444444;
      margin: 0px;
    }

    .terms-description-upper {
      background-color: #f7f7f7;
      padding: 0px 0px 7px 30px;
      border-radius: 8px;
      margin-bottom: 15px;

      ul {
        padding-left: 10px;

        li {
          position: relative;
          padding-left: 20px;
          margin-bottom: 5px;

          &:after {
            position: absolute;
            content: "";
            height: 6px;
            width: 6px;
            background-color: #000000;
            left: 0;
            border-radius: 50%;
            top: 8px;
          }
        }
      }

      .terms-description {
        height: 140px;
        overflow-y: scroll;
        color: #444444;
        font-size: 13px;
        line-height: 21px;
        display: inline-block;
        padding-right: 20px;

        a{
          color: #1677ff;
        }    
          
        .terms-info-list {
               a{
                  color: #1677ff;
                } 
        }

        &::-webkit-scrollbar-track {
          background-color: #f0f0f0;
        }

        &::-webkit-scrollbar {
          width: 8px;
          background-color: #f0f0f0;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: #c1c1c1;
        }

        h4 {
          padding: 0px;
          color: #000;
          font-size: 18px;
          margin-bottom: 6px;
        }

        p {
          margin-bottom: 15px;
        }
      }
    }

    .ant-checkbox-wrapper {
      font-size: 13px;
      margin-top: 10px;

      span {
        color: rgba(0, 0, 0, 0.85) !important;
      }
    }
    .btn-with-toogle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      button.ant-switch {
        width: 100px;
        height: 23px;
      }
    }
  }

  .review-total-main {
    .total-with-checkout {
      font-size: 18px;

      .today-monthly-r {
        position: relative;
        margin-left: 35px;

        &:after {
          content: "";
          height: 100%;
          top: 1px;
          -webkit-transform: rotate(22deg);
          -ms-transform: rotate(22deg);
          transform: rotate(22deg);
          left: -21px;
        }
      }
    }
  }
}
.toggle-btn {
  margin-left: auto;
  margin-right: 40px;
}

.review-header .d-none {
  display: block;
}

.broadband-label-container {
  width: 100%;
  max-width: calc(67% - 15px);
  margin: auto;

  .rightsidebar-cart .collected-product .item-plan > div:first-child {
    text-transform: none;
  }

  .broadband-plan-earthlink-logo {
    width: 4rem;
    margin-top: -3rem;
  }

  .label-heading {
    font-size: 26px !important;
    color: #ef8905 !important;
    margin-top: 2rem;
  }
}

.label-footer {
  margin-bottom: 8px;
}

.label-section {
  border-bottom: 0 !important;
}

.review-left-side {
  .rightsidebar-cart {
    background-color: transparent;
    padding: 0;
  }

  .review_order_wrapp {
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 40px;
    padding: 20px;

    .common-cart-section {
      padding: 0;
    }

    &.p_review_order_wrapp {
      .common-cart-section {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #d3d3d3;
        &:last-child {
          padding-bottom: 0;
          margin-bottom: 0;
          border-bottom: none;
        }
      }
    }
    .left-total {
      font-weight: normal;
      b {
        font-weight: 800;
      }
    }
  }

  .review-button {
    margin-top: 30px;
    > .blue-button {
      margin: 0;
      margin-left: auto;
    }
  }
}
