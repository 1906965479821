$primary-color: #007dba;
$secondary-color: #31a3e0;
$white-color: #ffffff;

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
  cursor: pointer;
  background: $primary-color;
  color: $white-color;
  top: 0;
  z-index: 99;

  h4 {
    color: $white-color;
  }
}

.address-modal {
  .ant-modal-content{
    padding: 0;
  }
  .ant-modal-header {
    background: $primary-color;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
    color: #000000d9;
    padding: 16px 24px;
  }

  .ant-modal-title {
    color: $white-color;
  }

  .ant-modal-body {
    padding: 0 24px 24px;
  }

  .ant-input {
    height: 38px;
  }

  .ant-select {
    width: 100%;

    .ant-select-selector {
      padding-top: 3px;
      border-radius: 2px;
      height: 38px;
      width: 100%;
    }
  }
}

.location-icon {
  color: $primary-color;
}

.address-header {
  display: flex;
  align-items: baseline;
  margin-top: 10px;
}

.address-text {
  margin-left: 3px;
  font-size: 14px;
  margin-bottom: 14px;
}

.address-label {
  font-size: 14px;
}

.row-container {
  display: grid;
  gap: 10px;
  align-items: center;

  a {
    font-weight: bold;
    color: $primary-color;
    &:hover {
      color: $secondary-color;
    }
  }
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;

  .ant-btn {
    background-image: none;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    box-shadow: 0 2px 0 rgb(0 0 0 / 1.5%); 
    color: #000000d9;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    height: 32px;
    line-height: 1.5715;
    padding: 4px 15px;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
    user-select: none;
    white-space: nowrap;
}
  .cancel-btn:not([disabled]):hover {
    color: $primary-color ;
    border-color: $primary-color ;
    background: $white-color ;
  }
  .submit-btn:not([disabled]) {
    font-weight: bold;
    background-color: $primary-color;
    
    &:hover {
      background-color: $secondary-color ;
      border-color: $secondary-color ;
    }
  }
}

.edit-address-icon {
  padding-top: 10px;
  margin-left: 5px;
  color: $primary-color;
}

.close-circle-icon {
  color: $primary-color;
  font-size: 16px;
  vertical-align: bottom;
}
