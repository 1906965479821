.result-heading {
  padding: 30px 0 32px;

  > .result-heading {
    padding: 0;
  }

  h1 {
    color: #444444;
    font-size: 40px;
    font-weight: 900;
    margin-top: 8px; 
    margin-bottom: 0;
  }

  p {
    color: #444444;
    margin: 0;
    font-size: 18px;
    margin-top: 12px;

    span,
    i {
      color: #007dba;
      text-decoration: underline;
    }

    i {
      cursor: pointer;
    }
  }
  button {
    background-color: transparent;
    color: #007dba;
    border: none;
    cursor: pointer;
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    margin-top: 4px;
  }
}

.filters-top {
  position: absolute;
  width: 100%;
  left: 0;

  .top-filter.without-bundle {
    column-gap: 2%;

    .ant-col-6 {
      max-width: 32%;
      flex: 0 0 32%;
    }
  }
  .top-filter {
    column-gap: 2.6%;

    .ant-col-6 {
      max-width: 23%;
    }
  }

  .single-filter {
    cursor: pointer;
    border-radius: 5px;
    padding: 10px 0;
    background-color: #fff;
    border: 2px solid #f0f0f0;

    h4 {
      color: #007dba;
      margin: 0;
      font-size: 17px;
      font-weight: bold;
    }

    p {
      color: #444444;
      margin: 0;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .single-filter.active-filter {
    background-color: #007cb0;
    color: #fff;

    h4,
    p {
      color: #fff;
    }
  }
}

#root .tabfixed {
  position: fixed;
  width: 100%;
  top: 81px;
  left: 0;
  z-index: 99;
  margin: 0 !important;
  background-color: #fff;
  padding-bottom: 15px;
  border-bottom: 1px solid #d9d9d6;
}

.plans-row {
  margin-top: 7rem;

  .plan-heading.mobile-heading {
    margin-top: 2rem;
  }
  .plan-heading {
    margin-bottom: 1.5rem;

    h3 {
      font-size: 23px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 0;
    }

    i {
      font-size: 14px;
      background-color: #f68d1f;
      color: #fff;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      border: 2px solid #cf700c;
      margin-right: 12px;
    }
  }
}
.all-single-plans {

  > .ant-row {
    row-gap: 20px;
    margin: 1rem -10px 0;
    align-items: flex-start;
  }

  .plan-single {
    padding: 0 10px;
  }

  .plan-single-inner {
    font-family: Montserrat, sans-serif;
    background-color: #fff;
    text-align: center;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    height: 100%;
    position: relative;
    min-height: 200px;

    .grey-button {
      padding: 7px 14px;
    }
    .grey-button[disabled] {
      padding: 7px 18px;
    }

    h4 {
      margin: 15px 0 0;
      color: #007dba;
      font-size: 23px;
      font-weight: bold;
      display: inline-block;
    }

    h5 {
      color: #333333;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    ul {
      margin: 0;
      list-style-type: none;
      text-align: left;
      color: #333333;
      font-size: 15px;
      padding-left: 36px;

      li {
        position: relative;

        &:after {
          position: absolute;
          content: "";
          height: 6px;
          width: 6px;
          background-color: #007bb0;
          left: -15px;
          border-radius: 50%;
          top: 8px;
        }

        p {
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          padding-right: 10px;
        }
      }
    }

    .bundle-info {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      p {
        font-weight: bold;

        > span {
          margin-left: 10px;
        }
      }
    }

    .bundle-select {
      position: relative;
      margin-bottom: 5px !important;
      margin-left: -8px;

      .ant-select-selection-item {
        text-align: center;
      }

      .ant-select-arrow {
        color: #007dba;
        font-weight: bolder;
        font-size: 16px;
        right: 0;
      }
    }

    ul.bundle-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      padding-right: 4px;
      list-style: none;
      padding-left: 10px;
      li.icon-list {
        display: flex;
        gap: 8px;
        .icons-list-details span {
          color: #007dba;
          font-size: 20px;
        }
      }
      li::after {
        display: none;
      }
    }
    .learnmore {
      font-size: 16px;
      color: #007dba;
      text-decoration: underline;
      i {
        margin-left: 10px;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .down-cart {
      padding: 14px;
      border-top: 1px solid #d3d3d3;
      bottom: 0;
      width: 100%;

      button {
        i {
          margin-left: 10px;
          font-size: 16px;
        }
      }

      span.total-single {
        color: #444444;
        font-weight: bold;
        font-size: 12px;
      }
    }

    .main-below-total {
      text-align: right;

      .discount {
        color: #b3b5b8;
        font-size: 14px;
        position: relative;
        width: 100%;
        display: inline-block;

        &:after {
          position: absolute;
          content: "";
          height: 42px;
          background-color: #ff0000;
          width: 1px;
          -webkit-transform: rotate(71deg);
          -ms-transform: rotate(71deg);
          transform: rotate(71deg);
          top: -13px;
          left: auto;
          right: 25px;
        }
      }
    }
    .below-list {
      background-color: whitesmoke;
      margin: 0;
      overflow: auto;
      height: 140px;

      .list-bundle {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-right: 4px;
        padding-left: 25px;

        .learnmore-heading {
          color: #007dba;
          font-size: 16px;
          font-weight: 700;
          text-decoration: underline;
        }
      }
    }
  }

  .plan-single-inner.Seeless .down-cart {
    position: inherit;
  }

  .Seeless ul li p {
    display: block;
  }

  .plan-single.mostPop {
    position: relative;

    &:before {
      content: "Most Popular";
      position: absolute;
      color: #333333;
      top: -25px;
      left: auto;
      z-index: 9;
      right: 10px;
      background-color: #f68d1f;
      font-weight: bold;
      font-size: 16px;
      padding: 3px 10px;
      border-radius: 4px 4px 0 0;
    }

    .plan-single-inner {
      font-family: Montserrat, sans-serif;
      border-radius: 4px 0 4px 4px;
    }
  }

  .plan-single .seemore {
    background-color: transparent;
    border: none;
    color: #007cb0;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin: 8px 0 15px;
    padding-left: 20px;
    text-align: left;
    text-decoration: underline;
    width: 100%;
  }

  .wireless-prod {

    .down-cart {
      padding: 14px 10px;

      span.total-single {
        font-size: 13px;
      }

      a.blue-button-phone {
        font-size: 13px;
        padding-left: 5px;
        padding-right: 5px;
        i {
          margin-right: 6px;
        }
      }
    }
  }
}
.all-single-plans.internet-promo {
  margin-top: 2rem;
}

.bundle-outer,
.internet-outer {
  margin-bottom: 2.3rem;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.mobilephone-outer {
  margin-bottom: 3rem;
  .plan-single-inner {
    min-height: 210px;
  }

  .plan-heading {
    i {
      font-size: 16px;
    }
  }

  .all-single-plans .mobilephone-quantity {
    -ms-flex-preferred-size: 35%;
    flex-basis: 35%;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      appearance: textfield;
    }

    button {
      padding: 0;
      background-color: inherit;
      border: unset;
      color: #fff;
      cursor: pointer;

      i {
        margin: 0;
        font-size: 13px;
        height: 20px;
        width: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background-color: #007cba;
        border-radius: 50%;
      }
    }

    button[disabled] {
      i {
        background-color: #d9d9d6 !important;
        cursor: not-allowed;
      }
    }

    .counter-main {
      margin: 0 10px;
    }

    p {
      color: #777;
      font-size: 16px;
      margin-bottom: 6px;
      font-weight: 600;
    }
  }

  .mobilepone-subtotal p {
    color: #777;
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 600;
  }
}

.product-heading {
  font-weight: bold;
}

.rightsidebar-cart {
  background-color: #fff;
  padding: 20px; 
  border-radius: 8px;

  ul {
    list-style-type: none;

    li {
      margin-top: 5px;
    }
  }

  .common-cart-section {
    padding: 15px 0;

    span.edit-icon i {
      color: #007cba;
      cursor: pointer;
      margin: 0 6px 0 0;
      font-size: 14px;
    }
  }

  .b-top {
    border-top: 1px solid #d3d3d3;
  }

  h3 {
    color: #444444;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;

    .fa-cart-shopping {
      color: #f68d1f;
      margin-right: 8px;
    }
  }

  span.prdouct-count {
    height: 20px;
    width: 20px;
    color: #444444;
    border-radius: 50%;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
    margin-left: 5px;
    letter-spacing: 1.2px;
  }

  .collected-product {
    list-style-type: none;
    margin-top: 10px;

    .dotted {
      display: none !important;
    }

    > li {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .item-plan {
      position: relative;
      margin-top: 12px;


      > div {
        z-index: 9;
        background-color: #fff;
        padding: 0 2px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        &:first-child {
          text-transform: capitalize;
        }
      }
    }

    .delete-product i {
      display: inline-block;
      color: #ff3c38;
      margin: 0;
      font-size: 13px;
      cursor: pointer;
    }
  }

  .product-collect-heading {
    p {
      color: #444444;
      font-size: 13px;
      font-weight: 800;
      line-height: 15.85px;
    }
  }

  .added-products {
    color: #444444;
    font-size: 13px;
    font-weight: 600;
    line-height: 15.85px;
  }

  .cart-terms {
    font-size: 13px;
    line-height: 15.85px;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #d3d3d3;

    .term-title {
      font-weight: 800;
      cursor: pointer;
      position: relative;
      padding-left: 20px;
      
      .cart-terms-icon {
        display: inline-block;
        width: 15px;
        height: 15px;
        line-height: 15px;
        text-align: center;
        color: #f08b20;
        border: 1px solid #f08b20;
        border-radius: 100%;
        position: absolute;
        left: 0;
      }
    }
  }

  .broadband-label-heading {
    font-size: 18px;
    cursor: pointer;

    .external-link-icon {
      i {
        color: #007cba;
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .terms-text {
    p {
      font-size: 13px;
      color: #57585c;
      font-weight: 600;
      margin-top: 8px;
    }
  }

  li span,
  .one-time-change span {
    font-weight: 600;
    margin: 0 0 4px;
    display: inline-block;
  }

  .total-with-checkout {
    color: #444444;
    font-size: 14px;
    text-align: center;

    button {
      margin: 20px auto 10px;
    }

    .left-total {
      font-size: 18px;
      line-height: 21.94px;
      font-weight: 800;
    }

    .left-total,
    .right-total {
      width: 50%;
    }

    .today-monthly-l {
      margin-right: 5px;
    }

    .today-monthly-r {
      position: relative;
      margin-left: 22px;

      &:after {
        position: absolute;
        content: "";
        height: 100%;
        width: 2px;
        background-color: black;
        top: 0;
        // -webkit-transform: rotate(20deg);
        // -ms-transform: rotate(20deg);
        transform: rotate(20deg);
        transform: rotate(20deg);
        left: -13px;
      }
    }
  }
}

.back-btn {
  font-size: 23px;
  font-weight: bold;
  cursor: pointer;
  margin: 15px 0;
  display: block;

  .back-icon {
    padding-right: 10px;
  }
}

.mobile-info-last {
  margin: 4%;
}

#root .mobile-info h3 {
  padding: 5px 0 0;
  margin-bottom: 0;
  line-height: 13px;
}

.common-pannel {
  background-color: #fff;
  padding: 25px 20px;
  border-radius: 8px;

  h3 {
    font-size: 20px;
    font-weight: 800;
    line-height: 24.38px;
    color: #444444;
    margin-bottom: 10px;
  }

  ul {
    list-style-type: none;
    background-color: #fff;

    li {
      color: #57585c;
      font-size: 13px;
      font-weight: 500;
      line-height: 24px;
      position: relative;
      padding-left: 20px;
      margin-top: 16px;
      letter-spacing: 0.5px;

      &:before {
        position: absolute;
        content: "";
        height: 6px;
        width: 6px;
        background-color: #f58b21;
        left: 5px;
        border-radius: 50%;
        top: 7px;
      }
    }
  }
}
// footer fixed
.result-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #007dba;
  color: #fff;
  z-index: 99;
  // transition: all 0.5s cubic-bezier(0.43, 0.83, 0.76, 1.04) 0s;
  transition: all 0.5s cubic-bezier(0.43, 0.83, 0.76, 1.04) 0s;
}

.reult-cart {
  -webkit-transition: all 0.5s cubic-bezier(0.43, 0.83, 0.76, 1.04) 0s;
  transition: all 0.5s cubic-bezier(0.43, 0.83, 0.76, 1.04) 0s;
  background-color: #fff;
  position: fixed;
  color: #2b2b2b;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1070;
  // -webkit-transform-origin: right;
  // -ms-transform-origin: right;
  transform-origin: right;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  width: 22%;
  box-shadow: 6px -4px 20px 0 #ccc;
}

.fixed-cart-result {
  .blue-row {
    padding: 15px 25px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
  }

  .blue-button.outer-continue-btn {
    background: inherit;
    padding: 0;
    color: #fff;
    border: none;
    float: right;
    margin-right: 5px;

    i {
      margin-left: 6px;
      transition: 0.2s;
    }

    &:hover {
      i {
        transform: translateX(5px);
        transition: 0.2s;
      }
    }
  }

  .blue-button.outer-continue-btn[disabled] {
    color: #91cce9;

    &:hover {
      i {
        transform: translateX(0);
      }
    }
  }

  .result-fixed-m {
    margin: 1.5rem 0;
  }

  .angle-icon {
    font-size: 24px;
    cursor: pointer;
    transition: 0.3s;
  }

  .fixed-on-scroll {
    position: unset;
    overflow-x: scroll;
    height: 100%;
    padding-bottom: 6rem;

    &::-webkit-scrollbar-track {
      background-color: #fff;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #f0f0f0;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #c1c1c1;
    }
  }

  .top-fix-cart {
    color: #007dba;
    font-size: 30px;
    font-weight: bold;
    padding: 1rem 0 2rem;
    i {
      font-size: 25px;
      cursor: pointer;
    }
  }

  .on-result-remove,
  .b-top.result-none {
    display: none;
  }
}

.result-unfixd {
  .blue-button.outer-continue-btn {
    color: #007dba;
  }

  .result-fixed {
    background-color: #fff;
    color: #007dba;
    -webkit-transition: all 0.5s cubic-bezier(0.43, 0.83, 0.76, 1.04) 0s;
    transition: all 0.5s cubic-bezier(0.43, 0.83, 0.76, 1.04) 0s;
    bottom: -69px;
  }

  .reult-cart {
    // -webkit-transform: translateX(0);
    // -ms-transform: translateX(0);
    transform: translateX(0);
  }

  .angle-icon {
    .fa-angle-up {
      display: none;
    }

    .fa-xmark {
      display: block;
      padding: 6px 0;
    }
  }

  .charge-info {
    color: #444444;
  }
}

.angle-icon .fa-xmark {
  display: none;
}

.height-f {
  min-height: calc(100vh - 185px);
  -webkit-transition: all 0.5s cubic-bezier(0.43, 0.83, 0.76, 1.04) 0s;
  transition: all 0.5s cubic-bezier(0.43, 0.83, 0.76, 1.04) 0s;
}

.ant-popover-inner-content {
  padding: 12px 10px;
}

.tol-tip-summery li {
  list-style-type: none;
}

.empty-cart {
  text-align: center;
  padding: 1rem;
  font-size: 1rem;
}
