.call_agent_online {

    .agent-hidden {
        display: none;
    }

    .agent-full-width {
        max-width: 100%;
        flex: 100%;
    }
    .mobilephone-outer, .bundle-outer{
        .plan-heading {
            margin-bottom: 0px;
        }
        .all-single-plans{
            .ant-row{
                row-gap: 45px;
            }
        }
    }
    .all-single-plans {
        display: inline-block;
        width: 100%;

        >.ant-row {
            column-gap: 20px;
            row-gap: 75px;
        }
        // .plan-single.Discount{
        //     &::after{
        //         content:"Get started for $0 today";
        //     }
        // }
        .plan-single {
            max-width: 31%;

            &::after {
                padding: 14px 5px;
                font-size: 12px;
                position: absolute;
                top: -47px;
                left: 15px;
                width: 80px;
                height: 80px;
                line-height: 1.2;
                transform: rotate(-12deg);
                background-color: #f4971f;
                border: 2px solid #fff;
                border-radius: 50%;
                box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 18%);
                font-weight: 500;
                text-align: center;
                -webkit-transform: rotate(-12deg);
            }

            .plan-single-inner {

                .down-cart.show-promo {
                    display: none;
                }
            }
        }
    }
    .all-single-plans.promo-sticker{
        margin-top: 2.6rem;
        .plan-single{
            &::after{
                font-size: 12px;
                height: 83px;
                width: 83px;
                left: -15px;
                top: -52px;
                padding: 14px 6px;
            }
        }
    }
    .result-heading {
        padding: 30px 0px 1rem;
    }

    .agent-call-button {
        padding: 14px;
        border-top: 2px solid #e1e2e1;

        a {
            display: inline-block;
            width: 75%;
            font-size: 17px;
            font-weight: 600;

            i {
                font-size: 20px;
                transform: rotate(15deg);
                margin-left: 7px;
            }
        }
        a:hover, a:active, a:focus{
            color: #333;
    background-color: #31a3e0;
    border-bottom-color: #267dba;
        }
    }
.plan-heading{
    text-align: left;
    margin-bottom: 0px;
}

}

.agent_online_header {

    .h-gift-card {
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        text-align: center;

        i {
            color: #f4971f;
            font-size: 20px;
            margin-right: 5px;
            transform: rotate(-14deg);
        }
    }

    .header-cart {
        display: none;
    }
}
