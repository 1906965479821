@media(min-width:768px) {
    footer {
        margin-top: 2rem !important;
    }
    .product-page {
        footer {
            margin-bottom: 0px !important;
            display: inline-block;
            width: 100%;
            -webkit-transition: all .5s cubic-bezier(.43,.83,.76,1.04) 0s;
            transition: all .5s cubic-bezier(.43,.83,.76,1.04) 0s;
        }
    }
    .down-footer-remove{
        footer {
            margin-bottom: 0px !important;
            -webkit-transition: all .5s cubic-bezier(.43,.83,.76,1.04) 0s;
            transition: all .5s cubic-bezier(.43,.83,.76,1.04) 0s;
        }
        .height-f {
            min-height: calc(100vh - 117px);
            -webkit-transition: all .5s cubic-bezier(.43,.83,.76,1.04) 0s;
            transition: all .5s cubic-bezier(.43,.83,.76,1.04) 0s;
        }
    }
    .header-cart {
        pointer-events: none;
    }

}
@media(max-width:1366px){
    .reult-cart {
        width: 30%;
    }
}
@media(max-width:1199px) {
    .filters-top .top-filter {
        margin-left: 0px;
        margin-right: 0px;
    }

    .mobile-type-input {
        width: 9.5rem !important;
    }

    .top-filter {

        .single-filter {
            margin: 0px;
        }
    }

    #root .main-checkout .calender-row .calender-below-buttons {
        width: 48%;
    }

    #root .same-common.create-account input,
    #root .same-common.create-account span.ant-input-password,
    #root .same-common.create-account .ant-select,
    .same-common input {
        height: 38px;
    }

    #root .same-common.create-account .ant-select .ant-select-selector {
        height: 33px;
    }

    #root .without-fixed-footer {
        margin-bottom: 0px !important;
    }
    

    .vas-products .vas-main-products .vas-single-prod h3 {
        font-size: 16px;
    }
    #root .main-checkout .installNum .ant-select .ant-select-selector {
        width: 140px;
    }
    #root .wireless-prod .blue-button-phone {
        padding: 6px;
        font-size: 11px;
    }
    .all-single-plans .wireless-prod .down-cart span.total-single {
        font-size: 12px;
    }
    .vas-carousel .vas-selection {
        width: 100%;
    }
    .vas-carousel .slick-slider {
        margin: 0px;
    }
    .reult-cart {
        width: 35%;
    }
   body .call_agent_online .all-single-plans > .ant-row {
        column-gap: 18px;
        row-gap: 70px;
    }
}

@media(max-width:1024px) {
    .all-single-plans .wireless-prod .down-cart span.total-single {
        font-size: 11px !important;
    }
    .mobile-type-input {
        width: 9rem !important;
    }
    .blue-button {
        font-size: 13px;
    }

    .all-single-plans .plan-single-inner .down-cart button i.fa-times,
    .all-single-plans .plan-single-inner .down-cart button i.fa-cart-plus {
        margin-left: 18px;
    }

    .all-single-plans .plan-single-inner .down-cart span.total-single {
        font-size: 15px;
    }   
}

@media(max-width:991px) {
    .height-f {
        min-height: calc(100vh - 180px);
    }
    .mobile-type-input {
        width: 6.5rem !important;
    }
    #root .wireless-prod .blue-button-phone{
        justify-content: center;
    }

    #root .responsive-m-t-2 {
        margin-top: 2rem;
    }

    #root .responsive-p-b-2 {
        padding-bottom: 2rem;
    }

    .footer-resp.closed {

        .mobile-cart-info {
            display: none;
        }

        i.fa-angle-up {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.3s;
            -o-transition: 0.3s;
            transition: 0.3s;
        }
    }

    .footer-resp {
        .mobile-cart-info {
            display: block;
            max-height: 250px;
            overflow-x: scroll;
        }

        .header-bottom-fix {
            padding: 0px 0px 20px;
        }

    }
 
    #root .responsive-w-100 .ant-row,
    #root .responsive-width-n {
        display: block;

        .ant-col-16,
        .ant-col-8,
        .ant-col-12,
        .ant-col-15,
        .ant-col-4,
        .ant-col-1,
        .ant-col-19  {
            max-width: 100%;
        }
    }
    .create-account .input-span {
        bottom: 9px;
    }
    .blue-button {
        font-size: 12px;
        padding: 7px 10px;
    }

    .all-single-plans .plan-single-inner .down-cart button i.fa-cart-plus {
        margin-left: 12px;
        font-size: 13px;
    }

    .all-single-plans .plan-single-inner .down-cart button i.fa-times {
        margin-left: 5px;
    }

    .all-single-plans .plan-single-inner h5 {
        font-size: 16px;
    }

    .all-single-plans .plan-single-inner h4 {
        margin: 12px 0px 0px;
        font-size: 20px;
    }

    .all-single-plans .plan-single-inner .down-cart span.total-single {
        font-size: 12px;
    }

    .mobilephone-outer .all-single-plans .mobilephone-quantity p {
        font-size: 13px;
    }

    .mobilephone-outer .mobilepone-subtotal p {
        font-size: 13px;
    }

    .mobilephone-outer .all-single-plans .mobilephone-quantity button i {
        font-size: 10px;
        height: 18px;
        width: 18px;
    }

    footer {
        font-size: 14px;

        a {
            font-size: 14px;
        }
    }

    .rightsidebar-cart .total-with-checkout .left-total,
    .rightsidebar-cart .total-with-checkout .right-total {
        width: auto;
        font-size: 12px;
    }

    .rightsidebar-cart .product-collect-heading p {
        font-size: 12px;
    }

    .rightsidebar-cart .collected-product .item-plan div {
        font-size: 10px;
    }

    .common-pannel h3 {
        font-size: 16px;
    }

    .main-checkout .ant-picker-body .ant-picker-content {
        table-layout: inherit;
    }

    #root .main-checkout .calender-row .arrival-window-button button {
        padding: 3px 10px;
        font-size: 14px;
        width: 100%;
    }

    #root .main-checkout .calender-row .calender-below-buttons {
        width: 67%;
    }

    #root .main-checkout .calender-row h3.under-tab-heading {
        font-size: 15px;
    }

    .review-terms-condition {
        .ant-checkbox {
            align-self: start !important;
            span {
                font-size: 13px;
            }
        }
    }

    #root .main-checkout .ant-collapse-header {
        font-size: 19px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item {
        line-height: 24px;
    }

    p.item-text-set {
        -webkit-line-clamp: 2;
        line-clamp: 2;
        font-size: 14px !important;
    }

    .vas-products .vas-main-products .vas-single-prod h3 {
        font-size: 15px;
        padding: 0px 10px 5px;
        line-height: 21px;
        position: relative;

        span {
            position: absolute;
        }
    }

    .vas-products .vas-main-products .vas-single-prod {
        min-height: auto;
    }

    .vas-products .vas-main-products .vas-single-prod .below-selected-part {
        position: unset;
        display: block;
    }

    .vas-selection {
        height: 38px;
        margin-bottom: 15px;
    }

    .vas-products .vas-main-products .vas-single-prod .below-selected-part button {
        margin-left: 0px;
        width: 100%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .vas-products .vas-main-products .single-vas-text p {
        font-size: 15px;
        text-align: center;
        margin-top: 10px;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #root .main-checkout .installNum .ant-select .ant-select-selector {
        width: 95px;
    }
    #root .main-checkout .installNum .ant-collapse-content-box .ant-col-8 label {
        margin-right: 5px;
        font-size: 14px;
    }
    .check-loading-reponsive {
        width: 100%;
        margin-bottom: 50px;
    
        .loader-out {
            margin-top: 0px !important;
        }
    }
    .all-single-plans .plan-single-inner .down-cart{
        padding: 14px 8px;
    }
    .all-single-plans .plan-single-inner {
        min-height: 205px;
    }
    .mobilephone-outer .plan-single-inner {
        min-height: 245px;
        .mobilephone-quantity{
            .d-flex{
                justify-content: center;
                margin-bottom: 6px;
            }
        }
        .mobilepone-subtotal{
            border-top: 1px solid #ccc;
            padding-top: 6px;
        }
    }
    #root .down-cart {
        display: block;
        
        .blue-button {
            margin: 0 auto;
        }
        .main-below-total{
            text-align: center;
            margin-top: 5px;
        }
    }
    .reult-cart {
        width: 40%;
    }
    #root .main-checkout .pnl:before {
        font-size: 20px;
    }
    .main-review-part .review-left-side .dotted {
        top: 8px;
    }
    body .call_agent_online .all-single-plans > .ant-row {
        column-gap: 14px;
        row-gap: 66px;
    }
    body .call_agent_online .all-single-plans .plan-single::after {
        font-size: 11px;
        top: -61px;
        left: 6px;
        width: 80px;
        height: 80px;
        padding: 14px 7px;
    }
    body .call_agent_online .all-single-plans.internet-promo {
        margin-top: 3rem;
    }
}

@media(max-width:767px) {
    .responsive-none {
        display: none;
    }

    .whi-modal {
        padding-bottom: 600px;
    }

    .body-overflow-hide {
        overflow-y: hidden;
    }

    #root .on-footer-m-fix {
        padding: 0px 20px;
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        z-index: 9999;
        overflow-y: scroll;

        .responsive-none {
            display: block;
            padding-bottom: 55px;

        }

        .inner-fixed-ftr {
            .footer-inner-top-fix {
                padding: 15px 0px;
                display: grid;
                -ms-grid-columns: 1fr 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
            }
        }


        i.fa-angle-up {
            color: #007cba;
            font-size: 25px;
            -webkit-transition: 0.2s;
            -o-transition: 0.2s;
            transition: 0.2s;

        }

        button.outer-continue-btn {
            padding: 0;
            background-color: initial;
            border: none;
            float: right;
            color: #007cba;
            font-size: 14px;
            justify-content: flex-end;

            i {
                font-size: 18px;
                margin-left: 6px;
                color: #007cba;

            }
        }

        button.outer-continue-btn[disabled] {
            color: #7fbddc;

            i {
                color: #7fbddc;

            }
        }

    }

    footer {

        display: inline-block;
        width: 100%;
        padding-top: 15px;
        padding-bottom: 50px;
        background-position: center 75px;
        background-size: 16%;

        .elink-container {

            .d-flex {
                display: block;
                text-align: center;
            }
        }
    }

    .all-single-plans>.ant-row {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    #root .tabfixed,
    #root .filters-top {
        position: inherit;
        background-color: inherit;
        padding-bottom: unset;
        border-bottom: unset;
    }

    .plans-row {
        margin-top: 3rem;
    }

    .all-single-plans {
        margin-left: 0px;


    }

    #root .responsive-width-100 {
        width: 100%;
        max-width: 100%;
        -webkit-box-flex: 100%;
        -ms-flex: 100%;
        flex: 100%;
    }

    .main-below-total {
        display: none;
    }

    #root .responsive-width-set {
        max-width: 100%;
        -webkit-box-flex: 100%;
        -ms-flex: 100%;
        flex: 100%;
    }


    .on-footer-m-fix {
        .footer-cart-show-fiexd {
            display: none;
        }
    }

    .on-footer-m-fix.footer-cart-unfixed {
        overflow-y: scroll;
        height: calc(100vh);
        top: 0px;

        .footer-cart-show-fiexd {
            display: block;
        }

        i.fa-angle-up {
            -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
            -webkit-transition: 0.2s;
            -o-transition: 0.2s;
            transition: 0.2s;
        }
    }

    .close-btn {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 99999;


        >div {
            text-align: center;
            color: #007cba;
            font-size: 20px;
            font-weight: bold;
            background-color: #fff;
            padding: 5px 0px;
        }
    }



    .mobilephone-outer .plan-single-inner {
        min-height: 215px;
    }

    .down-cart {

        .blue-button {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin: 0 auto;
            font-size: 14px;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            padding: 7px 18px;
        }
    }

    .continue-btn-removed .footer-inner-top-fix {
        -ms-grid-columns: 2fr 2fr !important;
        grid-template-columns: 2fr 2fr !important;
    }

    #root .main-checkout .compatible-tab-checkout button {
        margin: 5px auto;
    }

    .result-compatible {
        margin-top: 0px;
    }

    .compatible-enter-info {
        display: block;
        text-align: center;

        button {
            margin: 10px auto !important;
        }
    }

    .compatible-tab-checkout .loader-out {
        width: 100%;
        margin: 0 auto 20px;
    }

    .create-account .Check-account {
        margin-top: 0px;
    }

    #root .main-checkout .checkout-accordion {
        margin-bottom: 35px;
    }

    .cart-page {
        .responsive-none {
            display: block;
        }
    }

    .calender-row .ant-picker-calendar .ant-picker-panel {
        width: 100%;
    }

    .calender-below-buttons {

        button {
            margin: 0px 10px;
            min-width: auto !important;
            padding: 7px 25px;
        }
    }

    #root .main-checkout .calender-row .main-calender {
        padding: 0px 0;
    }

    #root .main-checkout .calender-row .arrival-window-button button {
        position: relative;

        i {
            position: absolute;
            left: 20%;
            top: 6px;
            font-size: 15px;
        }
    }

    .email-suggest-msg {
        margin-top: 0px;
    }

    .vas-products .vas-main-products {
        -ms-grid-columns: 2fr 10px 2fr;
        grid-template-columns: 2fr 2fr;
        margin-bottom: 30px;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
    }

    .vas-products .vas-main-products .single-vas-text p {
        height: 42px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .vas-products .vas-main-products .vas-single-prod h3 {
        padding: 0px 0px 5px;
    }

    .vas-main-heading .vas-heading h1 {
        font-size: 23px;
    }

    .responsive-height {
        margin: 20px 0px;
        height: auto;
    }

    .vas-main-heading {
        margin: 0px;
    }

    .responsive-height .blue-button {
        font-size: 13px;
        padding: 7px 25px;
    }

    .vas-main-heading p {
        font-size: 15px;
        line-height: 26px;
    }

    .rightsidebar-cart {
        margin-bottom: 2rem;
    }

    .main-review-part {
        .review-left-side {
            padding-right: 0px;
            max-width: 100%;
        }
        .review-right-side{
            max-width: 100%;
        }
    }    
    .main-review-part .service-cart ul {
        -ms-grid-columns: auto;
        grid-template-columns: auto;
    }

    .result-heading {
        padding: 0px 0px 0.5rem;

        h1 {
            font-size: 30px;
        }
    }

    .main-review-part .review-left-side .common-cart-section {
        padding: 10px 10px;
    }

    .main-review-part .review-left-side h4 {
        padding: 18px 0px 0px 10px;
    }

    .staic-vas-products {
        -ms-grid-columns: auto;
        grid-template-columns: auto;
        row-gap: 2rem;
        margin: 0;
    }

    .main-thanks .account-detail .f-detail,
    .main-thanks .charges-detail {
        -ms-grid-columns: auto;
        grid-template-columns: auto;
    }

    .responsive-top-m {
        margin-top: 1rem;
    }

    .responsive-font-set {
        font-size: 13px !important;
        position: relative;
        display: inline-block !important;
        padding: 0px 25px;

    }

    .loader-text .carousel-outer i {
        position: absolute;
        margin-left: -25px;
        top: 0;
    }

    .check-loading-reponsive {
        margin-bottom: 27px;
    }

    .serviceabilityCheck .card .ant-select .ant-select-selector .ant-select-selection-item {
        width: 100%;
    }

    #root .same-common.create-account .ant-select .ant-select-selection-placeholder {
        line-height: 25px;
    }

    .mobilephone-outer .all-single-plans .mobilephone-quantity button i {
        font-size: 20px;
        height: 30px;
        width: 30px;
        border-radius: 2px;
    }

    .mobilephone-outer {
        .down-cart {
            -webkit-box-orient: vertical;
            -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;

            .mobilephone-quantity {
                width: 100%;

                .d-flex {
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
        margin-bottom: 0px;
    }

    .all-single-plans .plan-single-inner .down-cart {
        padding: 10px;
        position: inherit;
    }

    .body-loading .on-footer-m-fix {
        display: none;
    }

    .body-loading .on-footer-m-fix.footer-cart-unfixed,
    .body-loading .on-footer-m-fix.on-simple-load {
        display: block;
    }

    .p-top-5 {
        padding-top: 6rem;
    }

    .main-outer {
        height: calc(100vh - 145px);
    }

    .without-fixed-footer {
        margin-bottom: 0px !important;
    }
    #root .installNum .ant-collapse-content-box .ant-row{
        display: block;
        .ant-col-8{
            max-width: 100%;
            margin-bottom: 15px;
            .ant-select-selector{
                width: 100%;
            }
        }
    }
    .fixed-cart-result {
        display: none;
    }
    .all-single-plans .plan-single-inner,
    .mobilephone-outer .plan-single-inner {
        min-height: inherit;
    }
    .mobilephone-outer .plan-single-inner .mobilepone-subtotal{
        border: none;
        padding: 0px;
    }
    .all-single-plans .plan-single-inner .down-cart span.total-single {
        font-size: 15px; 
    }
    header .header-tfn a {
        font-size: 13px;
    }
    header .header-tfn {
        margin-right: 2px;
        i{
            font-size: 21px;
            margin-right: 4px;
            
        }
    }
    header .header-cart i {
        font-size: 18px;
    }
    header .header-cart span {
        font-size: 12px;
        height: 20px;
        width: 20px;
    }
    .all-single-plans .plan-single.mostPop:before {
        font-size: 12px !important;
        top: -25px;
    }
    .plans-row .plan-heading {
        margin-bottom: 1.5rem;
    }
    .vas-carousel .ant-carousel .slick-initialized .slick-slide {
        padding: 15px 24px 0px;
    }
    .vas-carousel .slick-prev{
        left: 0px;
    }
    .vas-carousel .slick-next {
        right: 0px;
    }
    .vas-products .vas-main-products .single-vas-text p {
        height: auto;
    }
    .vas-main-heading .vas-heading h2 {
        font-size: 20px;
    }
    .grid-col-auto {
		grid-template-columns: auto !important;
	}
	.outer-thanks .main-thanks {
		.order-daetil-sec,
		.account-detail,
		.charges-detail
		{
			padding: 0px 15px !important;
		}
        p{
            font-size: 14px;
        }
	}
	.outer-thanks .main-thanks {
        margin-bottom: 0px;
		.total-billing {
			padding: 10px 15px !important;
		}
	}
    body .call_agent_online .all-single-plans .plan-single {
        max-width: 46%;
    }
    body .call_agent_online .agent-call-button a {
        width: 100%;
        font-size: 15px;
    }
    body .agent_online_header {
        .elink-container{
            position: relative;
            padding-bottom: 2.5rem;
        }
        
        .h-gift-card{
            position: absolute;
            bottom: 0;
            width: 100%;
            text-align: center;
            left: 0;
        }
    }
    .agent-full-width{
        .internet-outer {
            margin-bottom: 0px;
        }
    }
    body .mobilephone-outer{
        .all-single-plans{
            .ant-row{
                row-gap: 0px !important;
            }
        }
    }
    body .call_agent_online .all-single-plans.internet-promo {
        margin-top: 2rem;
    }
    body .call_agent_online .all-single-plans.internet-promo > .ant-row {
        row-gap: 18px;
    }
    body .call_agent_online .all-single-plans .plan-single::after {
        top: -43px;
        left: -8px;
        width: 69px;
        height: 66px;
        line-height: 9px;
        font-size: 9px;
    }
    .plans-row .plan-heading h3 {
        font-size: 19px;
    }
    .call_agent_online .mobilephone-outer .all-single-plans .ant-row,
    .call_agent_online .bundle-outer .all-single-plans .ant-row {
        row-gap: 0px !important;
    }
}

@media(max-width:480px) {
     .logo a img {
        width: 140px;
    }

    header .header-cart span {
        font-size: 14px;
    }

    .result-heading {
        padding: 0px 0px 1rem;

        h1 {
            font-size: 27px;

        }

        p {
            font-size: 14px;
        }
    }

    .filters-top .single-filter h4 {
        font-size: 13px;
        span{
            display: inline-block;
            width: 100%;
        }
    }

    .all-single-plans .plan-single{
        flex: 0 0 100%;
        max-width: 100%;
    }

    .plans-row .plan-heading h3 {
        font-size: 16px;
    }

    .plans-row {
        margin-top: 1rem;
    }

    .plans-row .plan-heading i {
        font-size: 11px;
        height: 23px;
        width: 23px;
    }

    .all-single-plans .plan-single-inner h4 {
        font-size: 18px;
        margin: 5px 0px 0px;
    }

    .all-single-plans .plan-single-inner h5 {
        font-size: 13px;
    }

    .all-single-plans .plan-single-inner ul {
        font-size: 12px;
        padding-left: 24px;
    }

    .all-single-plans .plan-single-inner {

        .down-cart {
            padding: 10px;

            .blue-button {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                margin: 0 auto;
                font-size: 14px;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                padding: 7px 18px;
            }
        }
    }

    .plan-heading {
        padding-bottom: 8px;
    }

    .mobilephone-outer {
        margin-bottom: 0px;
    }
    .all-single-plans .ant-col-12.plan-single{
        flex: 0 0 100%;
        max-width: 100%;
    }

    footer {
        padding-bottom: 50px;
        background-position: center 63px;
        background-size: 22%;

        .elink-container .d-flex {
            font-size: 13px;

            a {
                font-size: 13px;
            }
        }
    }

    .mobilephone-outer .all-single-plans .mobilephone-quantity .counter-main {
        font-size: 18px;
        font-weight: bold;
    }

    .all-single-plans .grey-button {
        padding: 7px 10px;
    }

    .filters-top {
        .elink-container {
            padding: 0px;
        }
    }

    #root .main-checkout .ant-collapse-header {
        font-size: 16px;
        padding: 20px 30px 20px 35px;
    }

    #root .main-checkout .ant-collapse-content-box {
        padding: 0px 10px 15px;
    }

    #root .main-checkout .calender-row .arrival-window-button button i {
        left: 12%;
    }

    #root .main-checkout .compatible-tab-checkout .compatible-input {
        margin-right: 0px;
    }

    .suggestedIds {
        display: block;
    }

    .suggestionsContainer {
        display: block;
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }

    .your-info-tab .enter-your-info .blue-button {
        margin: 0rem auto 0px;
    }

    #root .main-checkout .tab-icon .ant-collapse-header:after {
        top: 21px;
        right: 0.6rem;
        font-size: 16px;
    }

    .create-account .loader-out {
        margin-top: 0px;
        margin-left: 0px;
    }

    .create-account .your-info-tab {
        height: 40px;
    }

    .main-checkout .blue-button {
        padding: 7px 26px !important;
        font-size: 14px !important;
    }

    .vas-products .vas-main-products .vas-single-prod {
        min-height: auto;
    }

    .vas-products .vas-main-products .vas-single-prod h3 {
        font-size: 14px;
        padding: 0px 14px 0px 0px;
        text-align: left;
    }

    .main-review-part .review-left-side h4 {
        padding: 10px 0px 0px 8px;
        font-size: 18px;
    }

    .main-review-part .service-cart ul li {
        font-size: 14px;
        margin: 3px 0px;
    }

    .main-review-part .service-cart ul span {
        font-size: 15px;
        margin: 0px !important;
    }

    .main-review-part .review-left-side .product-collect-heading p {
        font-size: 14px;
    }

    .main-review-part .review-total-main .total-with-checkout .today-monthly-r {
        position: relative;
        margin-left: 30px;
    }

    .main-review-part .review-terms-condition {
        text-align: left;
        margin: 1rem 10px;
    }

    .main-review-part .review-terms-condition h5 {
        font-size: 14px;
    }

    .main-review-part .review-terms-condition .terms-description-upper {
        padding: 15px 10px 0px 10px;
    }

    .main-review-part .review-terms-condition .terms-description-upper .terms-description h4 {

        font-size: 14px;
    }

    .main-review-part .review-terms-condition .terms-description-upper ul li {
        font-size: 13px;
    }

    .main-review-part .review-terms-condition .ant-checkbox-wrapper {
        font-size: 13px;
    }

    .main-thanks .account-detail,
    .main-thanks .charges-detail,
    .main-thanks .order-daetil-sec {
        padding: 0px 10px;
    }

    .main-thanks .total-billing {
        padding: 1rem 10px 0px;
    }

    .main-thanks h5,
    .main-thanks .order-daetil-sec p {
        font-size: 13px;
    }

    .main-thanks p {
        font-size: 11px;
    }

    .main-thanks .order-daetil-sec h4 {
        font-size: 22px;
        margin-bottom: 0px;
    }

    .charges-detail {
        ul {
            font-size: 13px;
        }
    }

    h2.static-vas-prod-heading {
        font-size: 21px;
        margin-top: 3rem;
    }


    .staic-vas-products .static-vas-inner .inner-vas-staic {

        p {
            font-size: 14px;

        }

        h3 {
            font-size: 19px;
        }

        b {
            font-size: 16px;
        }

        ul li {
            font-size: 14px;
        }
    }

    .staic-vas-products {
        margin: 1rem 0px 0rem;
    }

    .serviceabilityCheck h2 {
        font-size: 16px;
    }

    .serviceabilityCheck .card .blue-button .btn-color-darkblue {
        font-size: 14px;
    }

    .same-protect {
        padding: 10px;
        margin-top: 20px;

        h2 {
            font-size: 22px;
        }

        h4 {
            font-size: 18px;
        }

        h3 {
            font-size: 23px;
        }

        .bckup-plans {
            h2 {
                font-size: 30px;
            }

            h6 {
                font-size: 14px;
            }
        }
    }

    .responsive-font-set {
        font-size: 13px !important;
    }

    .vas-main-products .blue-button {
        padding: 7px 0px !important;
        font-size: 12px !important;
    }

    .ant-modal-title {
        font-size: 18px;
    }

    .your-info-tab label {
        font-size: 14px;
        .ant-checkbox{
            align-self: start !important;
        }
     
    }

    .main-outer {
        height: calc(100vh - 109px);
    }
    .plans-row .plan-heading {
        margin-bottom: 0.5rem;
    }
    .rightsidebar-cart h3 {
        margin: 0px;
    }
    #root .main-checkout .pnl:before {
        font-size: 16px;
    }
    body .call_agent_online .all-single-plans > .ant-row {
        column-gap: 0px;
    }
    body  .call_agent_online .agent-call-button {
        padding: 14px 5px;
    }
    body .call_agent_online .agent-call-button a {
        width: 100%;
        font-size: 11px;
        padding: 8px 5px;
    }
    body .call_agent_online .agent-call-button a i {
        font-size: 18px;
        transform: rotate(15deg) translateY(2px);
        margin-left: 0px;
        margin-right: 1px;
    }
    body .call_agent_online .all-single-plans .plan-single::after, body .call_agent_online .all-single-plans.promo-sticker .plan-single::after  {
        font-size: 9px;
        top: -49px;
        left: -16px;
        width: 66px;
        height: 66px;
        padding: 6px;
        line-height: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media(max-width:360px){
    .logo a img {
        width: 110px;
    }
}